import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { VscDebugBreakpointData } from 'react-icons/vsc'

function BlockHome3() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-4/5 mx-auto md:py-32 py-10">
        <div className="md:flex md:flex-row flex flex-col md:p-0 px-2 content-reverse">
          <div className="md:w-[50%] relative">
            <div
              className="md:w-full md:md:h-[600px] w-[300px] h-[300px] bg-cover bg-center rounded-full"
              style={{ backgroundImage: `url("${rpdata?.gallery?.[1]}")` }}
            ></div>
            <div
              className="w-[50%] h-[50%]  absolute bottom-0 right-0 border-white border-[15px] z-10 bg-cover bg-center rounded-full"
              style={{ backgroundImage: `url("${rpdata?.gallery?.[3]}")` }}
            ></div>
            <div
              className="w-[50%] h-[50%]  absolute md:bottom-[-100px] md:block hidden md:right-[210px] border-white border-[15px] z-10 bg-cover bg-center rounded-full"
              style={{ backgroundImage: `url("${rpdata?.gallery?.[5]}")` }}
            ></div>
          </div>

          <div className="md:w-[50%] py-4 md:px-8 px-3 self-center md:text-start text-center">
            {
              rpdata?.dbPrincipal?.licensed.length > 1 ?
                <h2 className="separator-about">
                  rpdata?.dbPrincipal?.licensed
                </h2>
                : <h2 className="separator-about pb-3">we have {rpdata?.dbPrincipal?.exprYears} years of experience </h2>

              }
                  <p className="pb-4">{rpdata?.dbHome?.[0].text}</p>
                  <ul className="list-inner-section pl-3 text-start">
                    {rpdata?.dbServices?.slice(0, 5).map((item, index) => {
                      return (
                        <li key={index} className="py-1 flex items-center">
                          <VscDebugBreakpointData />
                          <span>{item.name}</span>
                        </li>
                      )
                    })}
                  </ul>
                  <ButtonContent btnStyle='three' />
                  <div className="w-full">
                    <span className="separator-about"></span>
                  </div>
                </div>

        </div>
      </section>
    </>
  );
}

export default BlockHome3;
